function createProject(projectName, timeSpan, detailList) {
    const projectBase = {
        toString: function toString() {
            return `
                projectName: ${this.projectName},
                timeSpan: ${this.timeSpan}
            `;
        },
    };
    const project = Object.create(projectBase);
    project.projectName = projectName;
    project.timeSpan = timeSpan;
    project.detailList = detailList;
    return project;
}
const employers = {
    cgi: {
        employerName: 'CGI Federal',
        roles: [
            {
                title: 'Java Developer',
                titleDateRange: 'Dec 2021 - Jul 2023',
                projects: [
                    createProject('Open Payments, Centers for Medicare & Medicaid Services, Department of Health and Human Services', null, [
                        '<span class="fw-bold">Java</span> developer on <span class="fw-bold">WebSphere Application Server</span> to provide features and bug fixes for Reporting Entities to submit payments to Covered Recipients including Teaching Hospitals.',
                        'Provided the implementation, testing, and documentation of a requested field on multiple user work flows for users to track Context of Teaching Hospital Payment data using JSF for the UI and MyBATIS for persistence.',
                        'Tools: <span class="fw-bold">Git</span>, Java, <span class="fw-bold">Spring</span>, IBM WebSphere, JSF, HTML, JavaScript, CSS.',
                    ]),
                    createProject('Case Management Microservices, United State Citizenship and Immigration Services, Department of Homeland Security', null, [
                        'Java development providing features, defect resolution, and downstream <span class="fw-bold">production support</span> on a <span class="fw-bold">microservices</span> team responsible for over 15 <span class="fw-bold">REST web services</span> using <span class="fw-bold">Spring Boot</span> for case management of Applicants and Beneficiaries of USCIS.',
                        'Aided the rewrite of inherited microservices, ensuring quality by capturing existing behavior through automated <span class="fw-bold">controller layer tests</span>.',
                        'Authored <span class="fw-bold">JUnit 5</span> automated unit tests at the service layer and controller layer using <span class="fw-bold">Mockito</span> and <span class="fw-bold">Spring testing features</span> (SpringBootTest, WebMvcTest, MockMvc, MockBean).',
                        'Used <span class="fw-bold">JaCoCo</span> to report <span class="fw-bold">test coverage</span> including instruction coverage, branch coverage, and cyclomatic complexity.',
                        'Utilized <span class="fw-bold">Spring Data JPA</span> with Hibernate for database operations.',
                        'Aided in migration of microservices from Red Hat OpenShift to Amazon <span class="fw-bold">Elastic Kubernetes Service</span> (EKS), using Vault for secrets management.',
                        'Volunteered a text manipulation utility for frequently used operations by team mates.',
                        'Performed requirements gathering, analysis, design, implementation, automated testing, UAT testing, documentation, and support after deployment to the production environment.',
                        'Environment: Java 8, Spring Boot, Spring Data JPA with Hibernate, JUnit 5, Mockito, Windows, Gradle, Docker, Git, Jenkins, Visual Studio Code.',
                    ]),
                    createProject('Key Achievements', null, [
                        'Selected to join the production support team to test, code, and document the high visibility issue of redesigning a decoupled, cohesive <span class="fw-bold">validation pipeline of 22 input fields for Applicant and Beneficiary data</span> before transmission to the Social Security Administration.',
                        'Increased test coverage from under 20% to <span class="fw-bold">over 90% instruction and branch coverage</span> on multiple microservices using Mockito and JUnit, relying upon <span class="fw-bold">cyclomatic complexity</span> to ensure test completeness.',
                        'Selected for a cross-team code challenge to setup <span class="fw-bold">Docker multi-stage builds</span> and <span class="fw-bold">Spring Boot integration tests</span> loading the <span class="fw-bold">full Spring application context</span>, plus leveraging mocks for testing authentication with <span class="fw-bold">AWS Cognito</span>, to verify correctness at Docker image build time while staying within the required time constraint for deployment. The setup was <span class="fw-bold">adopted by the backend team lead</span>.',
                    ]),
                ],
            },
        ],
    },
    boeing: {
        employerName: 'Boeing',
        roles: [
            {
                title: 'Software Engineer',
                titleDateRange: 'Jul 2016 - Feb 2021',
                projects: [
                    createProject('Summary', null, [
                        'Full stack development including features and defect resolution for multiple Java applications including planning and performing implementation details of features and bug fixes.',
                        'Provided features and defects resolution for an alarm management system which provides operational users with satellite vehicle health status for a constellation of 66 satellite vehicles.',
                        'Performed builds and deployments using tools including Maven, Gradle, Nexus, and Jenkins.',
                        'Environment:  Java, Maven, JUnit, JavaScript, TypeScript, AJAX, HTML, CSS, JIRA, Eclipse, NetBeans, Linux',
                    ]),
                    createProject('Key Achievements', null, [
                        'Converted a legacy build system to a <span class="fw-bold">Maven Multi-Module setup</span>, reducing build time by <span class="fw-bold">over 50%</span>.',
                        'Prototyped a 2D map <span class="fw-bold">user interface</span> using <span class="fw-bold">Angular</span>, successfully demonstrating the visualization and capturing of spatial target data.',
                        'Volunteered a dirty word check tool that was <span class="fw-bold">adopted by the team’s technical leadership</span>.',
                    ]),
                ],
            },
        ],
    },
    certificationsAndAwards: {
        employerName: '',
        roles: [
            {
                title: '',
                titleDateRange: '',
                projects: [
                    createProject('CompTIA Security+', 'May 2023, QSVGK07M9DR11L9C', [
                        `Earners of the CompTIA Security+ certification have the knowledge and skills necessary to perform core security functions required of any cybersecurity role. CompTIA Security+ professionals know how to identify and address potential threats, attacks and vulnerabilities and they have established techniques in risk management, risk mitigation, threat management and intrusion detection.
                        Earners of the CompTIA Security+ certification have the knowledge and skills necessary to perform core security functions required of any cybersecurity role. CompTIA Security+ professionals know how to identify and address potential threats, attacks and vulnerabilities and they have established techniques in risk management, risk mitigation, threat management and intrusion detection.
                        `,
                        `
                        <figure>
                          <blockquote class="blockquote">
                          </blockquote>
                          <figcaption class="blockquote-footer">
                            verify cert at <cite title="CompTIA Security+ ce Certification"><a href="https://www.credly.com/badges/2686afb1-e3cb-4208-9c26-12afa3c57e9e" target="_blank" rel="noreferrer">credly.com</a></cite>
                          </figcaption>
                        </figure>
                        `.trim(),
                    ]),
                    createProject('AWS Certified Cloud Practitioner', 'August 2022, 0JTCWC22YM4Q1RKQ', [
                        'The AWS Certified Cloud Practitioner validates foundational, high-level understanding of AWS Cloud, services, and terminology.  This is a good starting point on the AWS Certification journey for individuals with no prior IT or cloud experience switching to a cloud career or for line-of-business employees looking for foundational cloud literacy.',
                        `
                        <figure>
                          <blockquote class="blockquote">
                          </blockquote>
                          <figcaption class="blockquote-footer">
                            <cite title="AWS Certified Cloud Practitioner"><a href="https://aws.amazon.com/certification/certified-cloud-practitioner/" target="_blank" rel="noreferrer">aws.amazon.com</a></cite>
                          </figcaption>
                        </figure>
                        `.trim(),
                    ]),
                    createProject('CIW Certified Internet Webmaster Associate', 'June 2010', [
                        `<p>Web Foundations<p>
                        <p>Internet Business</p>
                        <p>Network Technology</p>`.trim(),
                    ]),
                ],
            },
        ],
    },
};
export { employers };